import { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { toast } from 'react-toastify';

import {
  CurrencyDollarIcon,
  GlobeAmericasIcon
} from '@heroicons/react/24/outline';
import { StarIcon } from '@heroicons/react/20/solid';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductAction } from '../store/slices/productSlice';
import {
  addOrderToCartAction,
  getCartItemsFromLocalStorageAction
} from '../store/slices/cartSlice';
import Layout from '../components/Layout/Layout';

// const product = {
//   name: 'Basic Tee',
//   price: '$35',
//   href: '#',
//   breadcrumbs: [
//     { id: 1, name: 'Women', href: '#' },
//     { id: 2, name: 'Clothing', href: '#' }
//   ],
//   images: [
//     {
//       id: 1,
//       imageSrc:
//         'https://tailwindui.com/img/ecommerce-images/product-page-01-featured-product-shot.jpg',
//       imageAlt: "Back of women's Basic Tee in black.",
//       primary: true
//     },
//     {
//       id: 2,
//       imageSrc:
//         'https://tailwindui.com/img/ecommerce-images/product-page-01-product-shot-01.jpg',
//       imageAlt: "Side profile of women's Basic Tee in black.",
//       primary: false
//     },
//     {
//       id: 3,
//       imageSrc:
//         'https://tailwindui.com/img/ecommerce-images/product-page-01-product-shot-02.jpg',
//       imageAlt: "Front of women's Basic Tee in black.",
//       primary: false
//     }
//   ],
//   colors: [
//     { name: 'Black', bgColor: 'bg-gray-900', selectedColor: 'ring-gray-900' },
//     {
//       name: 'Heather Grey',
//       bgColor: 'bg-gray-400',
//       selectedColor: 'ring-gray-400'
//     }
//   ],
//   sizes: [
//     { name: 'XXS', inStock: true },
//     { name: 'XS', inStock: true },
//     { name: 'S', inStock: true },
//     { name: 'M', inStock: true },
//     { name: 'L', inStock: true },
//     { name: 'XL', inStock: false }
//   ],
//   description: `
//     <p>The Basic tee is an honest new take on a classic. The tee uses super soft, pre-shrunk cotton for true comfort and a dependable fit. They are hand cut and sewn locally, with a special dye technique that gives each tee it's own look.</p>
//     <p>Looking to stock your closet? The Basic tee also comes in a 3-pack or 5-pack at a bundle discount.</p>
//   `,
//   details: [
//     'Only the best materials',
//     'Ethically and locally made',
//     'Pre-washed and pre-shrunk',
//     'Machine wash cold with similar colors'
//   ]
// };

const policies = [
  {
    name: 'Delivery',
    icon: GlobeAmericasIcon,
    description: 'Order ships within 2 days'
  },
  {
    name: 'Money back Guaranteed',
    icon: CurrencyDollarIcon,
    description: 'Money back guarantee'
  }
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function ProductDetailsPage() {
  const { cartItems } = useSelector(state => state?.cart);
  const {
    loading,
    error,
    product: { product }
  } = useSelector(state => state?.products);

  const [selectedSize, setSelectedSize] = useState('');
  const [selectedColor, setSelectedColor] = useState('');

  const dispatch = useDispatch();
  const { id } = useParams();

  let productDetails = {};

  //get id from params
  useEffect(() => {
    dispatch(fetchProductAction(id));
  }, [id]);

  //Get cart items
  useEffect(() => {
    dispatch(getCartItemsFromLocalStorageAction());
  }, []);

  const productExists = cartItems?.find(
    item => item?._id?.toString() === product?._id.toString()
  );

  //Add to cart handler
  const addToCartHandler = () => {
    //check if product is in cart
    if (productExists) {
      return toast.error('Product already in cart');
    }
    //   //check if color/size selected
    if (selectedColor === '') {
      return toast.error('Please select a product color');
    }
    if (selectedSize === '') {
      return toast.error('Please select a product size');
    }
    dispatch(
      addOrderToCartAction({
        _id: product?._id,
        name: product?.name,
        qty: 1,
        price: product?.price,
        description: product?.description,
        color: selectedColor,
        size: selectedSize,
        image: product?.images[0],
        totalPrice: product?.price,
        qtyLeft: product?.qtyLeft
      })
    );
    toast.success('Product added to cart successfully');
    return dispatch(getCartItemsFromLocalStorageAction());
  };

  console.log(product);

  return (
    <Layout>
      <div className='mt-[100px]'>
        <main className='mx-auto mt-8 max-w-2xl px-4 pb-16 sm:px-6 sm:pb-24 lg:max-w-7xl lg:px-8'>
          <div className='lg:grid lg:auto-rows-min lg:grid-cols-12 lg:gap-x-8'>
            <div className='lg:col-span-5 lg:col-start-8'>
              <div className='flex justify-between'>
                <h1 className='pri_text text-3xl font-medium'>
                  {product?.name.toUpperCase()}
                </h1>
              </div>
              <p className='pri_text text-xl font-medium'>
                $ {product?.price}.00
              </p>

              {/* Reviews */}
              <div className='mt-4'>
                <h2 className='sr-only'>Reviews</h2>
                <div className='flex items-center'>
                  <p className='text-sm text-gray-500'>
                    {product?.reviews?.length > 0 ? product?.averageRating : 0}
                    {/* <span className="sr-only"> out of 5 stars</span> */}
                  </p>
                  <div className='ml-1 flex items-center'>
                    {[0, 1, 2, 3, 4].map(rating => (
                      <StarIcon
                        key={rating}
                        className={classNames(
                          +product?.averageRating > rating
                            ? 'text-yellow-400'
                            : 'text-gray-400',
                          'h-5 w-5 flex-shrink-0'
                        )}
                        aria-hidden='true'
                      />
                    ))}
                  </div>
                  <div
                    aria-hidden='true'
                    className='ml-4 text-sm text-gray-500'
                  ></div>
                  <div className='ml-4 flex'>
                    <Link
                      href='#'
                      className='pri_text text-sm font-medium hover:text-[#dddac790]'
                    >
                      <span className='text-gray-500'>
                        {product?.totalReviews}
                      </span>{' '}
                      reviews
                    </Link>
                  </div>
                </div>

                {/* leave a review */}
                <div className='mt-4'>
                  <Link to={`/add-review/${product?._id}`}>
                    <h3 className='pri_text text-sm font-medium'>
                      Leave a review
                    </h3>
                  </Link>
                </div>
              </div>
            </div>

            {/* Image gallery */}
            <div className='mt-8 lg:col-span-7 lg:col-start-1 lg:row-span-3 lg:row-start-1 lg:mt-0'>
              <h2 className='sr-only'>Images</h2>

              <div className='grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-3 lg:gap-8'>
                {product?.images?.map(image => (
                  <img
                    key={image.id}
                    src={image}
                    alt={image.imageAlt}
                    className={classNames(
                      image.primary
                        ? 'lg:col-span-2 lg:row-span-2'
                        : 'lg:block',
                      'rounded-lg'
                    )}
                  />
                ))}
              </div>
            </div>

            <div className='mt-8 lg:col-span-5'>
              <>
                {/* Color picker */}
                <div>
                  <h2 className='pri_text text-sm font-medium'>Color</h2>
                  <div className='flex items-center space-x-3'>
                    <RadioGroup
                      value={selectedColor}
                      onChange={setSelectedColor}
                    >
                      <div className='mt-4 flex items-center space-x-3'>
                        {product?.colors?.map(color => (
                          <RadioGroup.Option
                            key={color}
                            value={color}
                            className={({ active, checked }) =>
                              classNames(
                                active && checked ? 'ring ring-offset-1' : '',
                                !active && checked ? 'ring-2' : '',
                                'relative -m-0.5 flex cursor-pointer items-center justify-center rounded-full p-0.5 focus:outline-none'
                              )
                            }
                          >
                            <RadioGroup.Label as='span' className='sr-only'>
                              {color}
                            </RadioGroup.Label>
                            <span
                              style={{ backgroundColor: color }}
                              aria-hidden='true'
                              className={classNames(
                                'h-8 w-8 rounded-full border border-white border-opacity-40'
                              )}
                            />
                          </RadioGroup.Option>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>
                </div>

                {/* Size picker */}
                <div className='mt-8'>
                  <div className='flex items-center justify-between'>
                    <h2 className='pri_text text-sm font-medium'>Size</h2>
                  </div>
                  <RadioGroup
                    value={selectedSize}
                    onChange={setSelectedSize}
                    className='mt-2'
                  >
                    {/* Choose size */}
                    <div className='grid grid-cols-3 gap-3 sm:grid-cols-6'>
                      {product?.sizes?.map(size => (
                        <RadioGroup.Option
                          key={size}
                          value={size}
                          className={({ active, checked }) => {
                            return classNames(
                              checked
                                ? 'border-transparent bg-[#a7977e] text-white hover:bg-[#a7977e90]'
                                : 'border-gray-200 bg-white text-gray-900 hover:bg-gray-50',
                              'flex cursor-pointer items-center justify-center rounded-md border px-3 py-3 text-sm font-medium uppercase sm:flex-1'
                            );
                          }}
                        >
                          <RadioGroup.Label as='span'>{size}</RadioGroup.Label>
                        </RadioGroup.Option>
                      ))}
                    </div>
                  </RadioGroup>
                </div>
                {/* add to cart */}
                {product?.qtyLeft <= 0 ? (
                  <button777
                    style={{ cursor: 'not-allowed' }}
                    disabled
                    className='text-whitefocus:outline-none mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-gray-600 px-8 py-3 text-base font-medium text-white focus:ring-2 focus:ring-[#a7977e] focus:ring-offset-2'
                  >
                    Out of stock
                  </button777>
                ) : (
                  <button
                    onClick={() => addToCartHandler()}
                    className='mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-[#a7977e] px-8 py-3 text-base font-medium text-black hover:bg-[#a7977e90] focus:outline-none focus:ring-2 focus:ring-[#5f98b7] focus:ring-offset-2'
                  >
                    Add to cart
                  </button>
                )}

                {/* proceed to check */}
                {cartItems.length > 0 && (
                  <Link
                    to='/shopping-cart'
                    className='mt-8 flex w-full items-center justify-center rounded-md border border-transparent bg-red-500 px-8 py-3 text-base font-medium text-black hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                  >
                    Proceed to checkout
                  </Link>
                )}
              </>

              {/* Product details */}
              <div className='mt-10'>
                <h2 className='pri_text text-sm font-medium'>Description</h2>
                <div className='prose prose-sm mt-4 text-gray-500'>
                  {product?.description}
                </div>
              </div>

              {/* Policies */}
              <section aria-labelledby='policies-heading' className='mt-10'>
                <h2 id='policies-heading' className='sr-only'>
                  Our Policies
                </h2>

                <dl className='grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2'>
                  {policies.map(policy => (
                    <div
                      key={policy.name}
                      className='rounded-lg border border-gray-200 bg-gray-50 p-6 text-center'
                    >
                      <dt>
                        <policy.icon
                          className='mx-auto h-6 w-6 flex-shrink-0 text-gray-400'
                          aria-hidden='true'
                        />
                        <span className='mt-4 text-sm font-medium text-gray-900'>
                          {policy.name}
                        </span>
                      </dt>
                      <dd className='mt-1 text-sm text-gray-500'>
                        {policy.description}
                      </dd>
                    </div>
                  ))}
                </dl>
              </section>
            </div>
          </div>

          {/* Reviews */}
          <section aria-labelledby='reviews-heading' className='mt-16 sm:mt-24'>
            <h2 id='reviews-heading' className='pri_text text-lg font-medium'>
              Recent reviews
            </h2>

            <div className='mt-6 space-y-10 divide-y divide-gray-200 border-b border-t border-gray-200 pb-10'>
              {product?.reviews.map(review => (
                <div
                  key={review._id}
                  className='pt-10 lg:grid lg:grid-cols-12 lg:gap-x-8'
                >
                  <div className='lg:col-span-8 lg:col-start-5 xl:col-span-9 xl:col-start-4 xl:grid xl:grid-cols-3 xl:items-start xl:gap-x-8'>
                    <div className='flex items-center xl:col-span-1'>
                      <div className='flex items-center'>
                        {[0, 1, 2, 3, 4].map(rating => (
                          <StarIcon
                            key={rating}
                            className={classNames(
                              review.rating > rating
                                ? 'text-yellow-400'
                                : 'text-gray-400',
                              'h-5 w-5 flex-shrink-0'
                            )}
                            aria-hidden='true'
                          />
                        ))}
                      </div>
                      <p className='ml-3 text-sm text-gray-500'>
                        {review.rating}
                        <span className='sr-only'> out of 5 stars</span>
                      </p>
                    </div>

                    <div className='mt-4 lg:mt-6 xl:col-span-2 xl:mt-0'>
                      <h3 className='pri_text text-sm font-medium'>
                        {review?.message}
                      </h3>
                    </div>
                  </div>
                  <div className='mt-6 flex items-center text-sm lg:col-span-4 lg:col-start-1 lg:row-start-1 lg:mt-0 lg:flex-col lg:items-start xl:col-span-3'>
                    <p className='pri_text font-medium'>{review.user?.name}</p>
                    <time
                      dateTime={review.datetime}
                      className='ml-4 border-l border-gray-200 pl-4 text-gray-500 lg:ml-0 lg:mt-2 lg:border-0 lg:pl-0'
                    >
                      {new Date(review.createdAt).toLocaleDateString()}
                    </time>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    </Layout>
  );
}
