import React, { useEffect, useState } from 'react';
import { motion, MotionProps } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { FaYoutube } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { FiMapPin } from 'react-icons/fi';
import { MdOutlineEmail } from 'react-icons/md';
import HeaderBlockImage from '../assets/images/CellPhone copy.png';
import SocialImage1 from '../assets/images/Social_1.jpg';
import BenefitsImage from '../assets/images/BroCrate.webp';
import { categoriesData } from '../data/categoriesData';

export const Bento = () => {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  useEffect(() => {
    const allCategoriesData = categoriesData ? [...categoriesData] : [];
    const sortedData = allCategoriesData?.filter(a => a.featured === true);
    const firstSix = sortedData && sortedData.slice(0, 6);
    setData(firstSix);
  }, []);

  return (
    <div className='mx-4 flex flex-col justify-center bg-black pt-12 text-center xl:mx-0'>
      <h1 className='sec_text autoShow mb-6 mr-4 text-6xl sm:text-7xl'>
        <span className='font-thin'>NEWEST</span> ARRIVALS
      </h1>
      <div className='mb-20 w-full justify-center py-12 text-white'>
        <motion.div
          initial='initial'
          animate='animate'
          transition={{ staggerChildren: 0.05 }}
          className='mx-auto grid max-w-6xl grid-flow-dense grid-cols-12 gap-4'
        >
          <HeaderBlock data={data} />
          <SocialBlock data={data} />
          {/* <AboutBlock /> */}
          {/* <LocationBlock /> */}
          {/* <EmailListBlock /> */}
        </motion.div>
      </div>
    </div>
  );
};

const Block = ({ className, ...rest }) => {
  return (
    <motion.div
      variants={{
        initial: {
          scale: 0.5,
          y: 50,
          opacity: 0
        },
        animate: {
          scale: 1,
          y: 0,
          opacity: 1
        }
      }}
      transition={{
        type: 'spring',
        mass: 3,
        stiffness: 400,
        damping: 50
      }}
      className={twMerge('col-span-4 bg-slate-500', className)}
      {...rest}
    />
  );
};

const HeaderBlock = ({ data }) => {
  return (
    <Block
      whileHover={{ scale: 1.05, rotate: '-1.0deg' }}
      className='relative col-span-12 row-span-2 p-0 md:col-span-6'
    >
      <img
        src='https://res.cloudinary.com/dcinefb4x/image/upload/v1723244004/OR_Code_Social_Cell_Rev1_mxpn1y.png'
        alt=''
        className='h-full w-full overflow-hidden object-cover'
      />
      <h1 className='absolute left-4 top-1 mb-12 text-4xl font-medium leading-tight'>
        <span className='pri_text'>Apparel</span>
      </h1>
      <Link
        href='/contact-us'
        className='absolute bottom-4 right-4 flex items-center gap-1 bg-white px-2 py-1 text-2xl text-black hover:underline'
      >
        Sign Up <BsFillArrowRightCircleFill color='black' />
      </Link>
    </Block>
  );
};

const SocialBlock = ({ data }) => (
  <>
    {data.map(b => (
      <Block
        whileHover={{ rotate: '1.0deg', scale: 1.1 }}
        className='relative col-span-6 bg-slate-400 md:col-span-3'
        key={b.id}
      >
        <h1 className='absolute left-4 top-1 mb-12 text-4xl font-medium leading-tight'>
          <span className='pri_text'>{b.title}</span>
        </h1>
        <Link
          to={`/products?category=${b.url}`}
          alt=''
          className='grid h-full text-3xl text-black'
        >
          <img
            src={b.image_Url}
            alt=''
            className='h-full w-full overflow-hidden object-cover'
          />
        </Link>
      </Block>
    ))}
  </>
);

const AboutBlock = () => (
  <Block className='col-span-12 text-xl leading-snug'>
    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
    <span>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Quod, cum.
      Accusantium nesciunt, iste molestias cupiditate aut possimus recusandae
      commodi nulla doloremque eius nihil necessitatibus laudantium natus
      quibusdam assumenda? Et, quas? Quod rem expedita reprehenderit
      consectetur. Consequatur odio earum atque saepe.
    </span>
  </Block>
);

const LocationBlock = () => (
  <Block className='col-span-4 flex flex-col items-center gap-4 md:col-span-4'>
    <FiMapPin className='text-3xl' />
    <p>CyberSpace</p>
  </Block>
);

const EmailListBlock = () => (
  <Block className='col-span-8 bg-red-500'>
    <p className='mb-3 text-2xl text-white'>Join the mailing list</p>
    <form
      onSubmit={e => e.preventDefault()}
      className='flex items-center gap-2'
    >
      <input
        type='email'
        placeholder='Enter your email'
        className='w-full border border-zinc-700 bg-white px-3 py-1.5 transition-colors focus:border-red-300 focus:outline-0'
      />
      <button
        type='submit'
        className='flex items-center gap-2 whitespace-nowrap rounded bg-zinc-50 px-3 py-2 text-sm font-medium text-zinc-900 transition-colors hover:bg-zinc-300'
      >
        <MdOutlineEmail />
      </button>
    </form>
  </Block>
);
